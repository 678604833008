import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { PageContent } from "../features/brand-pages"

export const query = graphql`
  {
    allStrapiBrandPage(filter: { slug: { eq: "naturel" } }) {
      nodes {
        id
        slug
        strapiId
        underContents
        pageMainVisual {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        mainLogo {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    allStrapiProducts(
      filter: { brand: { slug: { eq: "naturel" } }, isDisplay: { eq: true } }
      sort: { order: ASC, fields: order }
    ) {
      nodes {
        id
        name
        excerpt
        catchPhrase
        slug
        mainImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default function NaturelPage() {
  const {
    allStrapiBrandPage: pageData,
    allStrapiProducts: products,
  } = useStaticQuery(query)

  return (
    <Layout>
      <SEO>
        <title>naturel｜フランソア</title>
        <meta
          name="description"
          content="おいしい。そしてヘルシー。フランソアのパン商品ラインナップ。"
        />
      </SEO>
      <PageContent
        pageData={pageData.nodes[0]}
        products={products.nodes}
        slug={pageData.nodes[0].slug}
      />
    </Layout>
  )
}
